import React from "react";

function VisorCounterPopUp() {
  return (
    <div>
      <div className="fixed z-20 overflow-y-auto bottom-0 left-[28%] w-full">
        <div className="flex items-center justify-center min-height-100vh self-center justify-items-center px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-95" />
          </div>
          <div className="relative z-50">
            <div className="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisorCounterPopUp;
