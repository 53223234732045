import { Helmet } from "react-helmet";
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Footer from "./Footer_2";
import ScrollProgressBar from "./ScrollProgressBar";
import Header from "./NavBar";
import BotonWhatsapp from "./botonWhatsapp/BotonWhatsapp";

function BaseLayout(props) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <Helmet>
        <title>
          {props.PageName} | {`${rpdata?.dbPrincipal?.name}`}
        </title>
        <meta
          name="description"
          content={`${rpdata?.dbAbout?.[0].text.substring(0, 150) + "..."}`}
        />
        <meta
          name={` ${rpdata?.dbPrincipal?.name}`}
          content={` ${rpdata?.dbPrincipal?.name}`}
        ></meta>
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <link rel="icon" href={rpdata?.dbPrincipal?.favicon} />
      </Helmet>
      <Header />
      <ScrollProgressBar />

      <main>{props.children}</main>

      {rpdata?.simpleWidgets?.map((item) => {
        if (item.val === "WhatsappBtn" && item.activo === true) {
          return <BotonWhatsapp />;
        } 
         if (item.val === "VisitsCounter" && item.activo === true) {
          return (
            <div className="visor_Counter z-50 md:block hidden">
              <div className="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
            </div>
          );
        }
        return null;
      })}

      <Footer />
    </>
  );
}

export default BaseLayout;